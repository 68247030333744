import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@hurtigruten/design-system-components';

import { PauseLine, PlayLine } from '@components/icons/Media';
import { useOnLoad } from '@hooks/useOnLoad';
import { clamp, debounce, getSnapPoints } from '@utils';
import {
  CarouselSlider,
  CarouselSliderDiscrete,
  CarouselSliderHero,
  IconButton
} from '@atoms';
import { useMediaQuery } from '@hooks';

const dragThreshold = 10;

const Carousel = ({
  items,
  activeItem = 0,
  onItemChange,
  enableScrollWrap = false,
  showSlider = true,
  showArrows = true,
  scrollSpeed = 3,
  isNoMargin = false,
  isSnapScroll = false,
  isNoBoundaryMargin = false,
  mode = 'primary',
  sliderType = 'continuous',
  sliderSize = 'normal',
  sliderPosition = 'below',
  isDarkSlider = false,
  isItemsRounded = true,
  isForcedItemHeight = false,
  itemsPerPage,
  itemContainerClassName,
  autoScrollInterval,
  hasDiscretePadding = false,
  hasLaptopPadding = false,
  isInFluid = false,
  sliderClassName,
  itemsHoverState = false,
  slideLeftRef,
  slideRightRef,
  isLaptop = false,
  isInsideDSLayout = false
}: ICarouselProps) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [carouselPosition, setCarouselPosition] = useState(0);
  const [isScrollLeftEnabled, setIsScrollLeftEnabled] = useState(false);
  const [isScrollRightEnabled, setIsScrollRightEnabled] = useState(true);

  const [isSnapScrolling, setIsSnapScrolling] = useState(false);
  const [snapPoints, setSnapPoints] = useState<number[]>([]);
  const [normalizedSnapPoints, setNormalizedSnapPoints] = useState<number[]>();
  const [activeSnapPoint, setActiveSnapPoint] = useState(0);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isResettingAutoScroll, setIsResettingAutoScroll] = useState(false);
  const [isAutoScrollStopped, setIsAutoScrollStopped] = useState(false);

  const carouselRef = useRef<HTMLUListElement>(null);
  const itemMargin = isNoMargin ? 0 : 8;

  /* isInsideDSLayout */
  /*
    Currently 'isInsideDSLayout' is only being used in the my-account page,
    because the default layout of the carousel gets clipped if it is constrained
    by the content area. As per design, the buttons in this variant of the
    carousel will appear outside the carousel items (if page width allows).
    Because we need to use an absolute positioned item we can't place the item
    half-way down the parent component without calculating the height.
  */
  const [carouselHeight, setCarouselHeight] = useState<number | null>(null);
  const nextButtonRef = useRef<HTMLDivElement>(null);
  const widthToShowButtonsOutsideLayout = '1278px';
  const showButtonsOutsideLayout = useMediaQuery(
    widthToShowButtonsOutsideLayout
  );

  useEffect(() => {
    if (!nextButtonRef.current || !carouselHeight) return;
    nextButtonRef.current.style.marginTop = `${
      Math.ceil(carouselHeight / 2) - 40
    }px`;
  }, [nextButtonRef.current, carouselHeight]);

  useEffect(() => {
    if (!carouselRef.current) return;
    const { offsetHeight } = carouselRef.current;
    setCarouselHeight(offsetHeight);
  }, [carouselRef.current]);
  /* ---------------------- */

  const startScroll = (
    event: Partial<React.MouseEvent> & Required<Pick<React.MouseEvent, 'pageX'>>
  ) => {
    if (!carouselRef.current) return;

    const { offsetLeft, scrollLeft: currentScrollLeft } = carouselRef.current;
    setIsScrolling(true);
    setStartX(event.pageX - offsetLeft);
    setScrollLeft(currentScrollLeft);
  };

  const getNearestSnapPoint = useCallback(
    (position: number) => {
      if (!snapPoints) return [0, 0];

      const distanceToSnapPoints = snapPoints.map((snapPoint) =>
        Math.abs(snapPoint - position)
      );
      const nearestSnapPointIndex = distanceToSnapPoints.indexOf(
        Math.min(...distanceToSnapPoints)
      );
      const nearestSnapPoint = snapPoints[nearestSnapPointIndex];

      return [nearestSnapPoint, nearestSnapPointIndex];
    },
    [snapPoints]
  );

  const updateSliderPosition = useCallback(
    debounce(() => {
      setIsSnapScrolling(false);
      setCarouselPosition(clamp(activeSnapPoint / scrollWidth, 0, 1));
    }, 150),
    [activeSnapPoint]
  );

  const stopScroll = () => {
    if (!isScrolling || !snapPoints || !carouselRef.current) return;

    setIsScrolling(false);

    if (isSnapScroll) {
      const leftX = carouselRef.current.scrollLeft;

      const [nearestSnapPoint, nearestSnapPointIndex] =
        getNearestSnapPoint(leftX);

      if (nearestSnapPointIndex === 0) {
        setIsScrollLeftEnabled(enableScrollWrap);
      }

      setIsScrollRightEnabled(nearestSnapPointIndex < snapPoints.length - 1);

      setActiveSnapPoint(nearestSnapPoint);
      carouselRef.current.scrollTo({
        behavior: 'smooth',
        left: nearestSnapPoint
      });

      setCarouselPosition(clamp(nearestSnapPoint / scrollWidth, 0, 1));

      onItemChange?.(nearestSnapPointIndex + 1);
    }
    setIsResettingAutoScroll(true);
  };

  const updateScrollWidth = () => {
    if (!carouselRef.current) return { newScrollWidth: 0, containerWidth: 0 };

    const containerWidth = carouselRef.current.offsetWidth;
    const newScrollWidth = carouselRef.current.scrollWidth - containerWidth;

    setScrollWidth(newScrollWidth);
    return { newScrollWidth, containerWidth };
  };

  const updateSnapPoints = () => {
    if (!carouselRef.current) {
      return;
    }
    const { newScrollWidth, containerWidth } = updateScrollWidth();
    const itemWidth = (carouselRef.current.children[0] as HTMLElement)
      ?.offsetWidth;

    if (!itemWidth) {
      return;
    }

    const newSnapPoints = getSnapPoints({
      numberOfItems: items.length,
      itemWidth,
      containerWidth,
      containerScrollWidth: newScrollWidth,
      isNoBoundaryMargin,
      itemMargin
    });

    const newNormalizedSnapPoints = newSnapPoints.map(
      (snapPoint) => snapPoint / newScrollWidth
    );

    setSnapPoints(newSnapPoints);
    setNormalizedSnapPoints(newNormalizedSnapPoints);
  };

  const onResize = debounce(() => {
    updateSnapPoints();
  }, 150);

  useEffect(updateSnapPoints, [items]);

  useEffect(() => {
    if (
      !activeItem ||
      !carouselRef.current ||
      !normalizedSnapPoints ||
      !snapPoints
    )
      return;

    setCarouselPosition(normalizedSnapPoints[activeItem - 1]);
    setActiveSnapPoint(snapPoints[activeItem - 1]);
    carouselRef.current.scrollLeft = snapPoints[activeItem - 1];
  }, [activeItem, normalizedSnapPoints, snapPoints]);

  useEffect(() => {
    setCarouselPosition(0);
  }, [items]);

  useOnLoad(
    () => {
      updateSnapPoints();
      setIsPageLoaded(true);
    },
    () => {
      void onResize();
    }
  );

  const scroll = (
    event: Partial<React.MouseEvent> & Required<Pick<React.MouseEvent, 'pageX'>>
  ) => {
    if (!isScrolling || !carouselRef.current) return;
    event.preventDefault?.();

    const clickX = event.pageX - carouselRef.current.offsetLeft;
    const walk = (clickX - startX) * scrollSpeed;
    carouselRef.current.scrollLeft = scrollLeft - walk;

    const newCarouselPosition = clamp((scrollLeft - walk) / scrollWidth, 0, 1);

    setIsScrollLeftEnabled(enableScrollWrap || newCarouselPosition > 0);
    setIsScrollRightEnabled(enableScrollWrap || newCarouselPosition < 1);

    setCarouselPosition(newCarouselPosition);
  };

  const scrollTouch = (event: React.TouchEvent<HTMLUListElement>) => {
    if (!carouselRef.current) return;

    const { pageX } = event.touches[0];
    const diffToStart = startX - pageX + carouselRef.current.offsetLeft;

    if (Math.abs(diffToStart) < dragThreshold) return;

    scroll({ pageX: pageX + Math.sign(diffToStart) * dragThreshold });
  };

  const startScrollTouch = (event: React.TouchEvent<HTMLUListElement>) => {
    startScroll({ pageX: event.touches[0].pageX });
  };

  const slideLeft = () => {
    if (!carouselRef.current || !snapPoints) return;

    setIsSnapScrolling(true);

    const fudgeFactor = 15;
    const leftX = carouselRef.current.scrollLeft - fudgeFactor;

    let nearestSnapPointLeft = Math.max(
      ...snapPoints.filter((snapPoint) => snapPoint < leftX)
    );

    if (Math.abs(nearestSnapPointLeft) === Infinity) {
      nearestSnapPointLeft = enableScrollWrap
        ? snapPoints[snapPoints.length - 1]
        : 0;
    }

    const nearestSnapPointLeftIndex = snapPoints.indexOf(nearestSnapPointLeft);
    if (nearestSnapPointLeftIndex === 0) {
      setIsScrollLeftEnabled(enableScrollWrap);
    }

    setIsScrollRightEnabled(nearestSnapPointLeftIndex < snapPoints.length - 1);

    setActiveSnapPoint(nearestSnapPointLeft);
    carouselRef.current.scrollTo({
      behavior: 'smooth',
      left: nearestSnapPointLeft
    });

    onItemChange?.(nearestSnapPointLeftIndex + 1);
  };

  const onScroll = () => {
    void updateSliderPosition();
  };

  const togglePause = () => {
    setIsAutoScrollStopped(!isAutoScrollStopped);
  };

  const slideRight = () => {
    if (!carouselRef.current || !snapPoints) {
      return;
    }
    setIsSnapScrolling(true);
    const fudgeFactor = 15;
    const leftX = carouselRef.current.scrollLeft + fudgeFactor;

    let nearestSnapPointRight = Math.min(
      ...snapPoints.filter((snapPoint) => snapPoint > leftX)
    );
    if (nearestSnapPointRight === Infinity) {
      nearestSnapPointRight = enableScrollWrap
        ? 0
        : snapPoints[snapPoints.length - 1];
    }

    const nearestSnapPointRightIndex = snapPoints.indexOf(
      nearestSnapPointRight
    );

    if (nearestSnapPointRightIndex === snapPoints.length - 1) {
      setIsScrollRightEnabled(enableScrollWrap);
    }

    setIsScrollLeftEnabled(nearestSnapPointRightIndex > 0);

    setActiveSnapPoint(nearestSnapPointRight);
    carouselRef.current.scrollTo({
      behavior: 'smooth',
      left: nearestSnapPointRight
    });

    onItemChange?.(nearestSnapPointRightIndex + 1);
  };

  const onSliderChange = (relativeSliderPosition: number) => {
    if (!carouselRef.current || !snapPoints) return;

    const newPosition = scrollWidth * relativeSliderPosition;

    setIsScrollLeftEnabled(enableScrollWrap || relativeSliderPosition > 0);
    setIsScrollRightEnabled(enableScrollWrap || relativeSliderPosition < 1);

    const snapPointDifferences = snapPoints.map((snapPoint) =>
      Math.abs(snapPoint - newPosition)
    );
    const smallestSnapPointDifference = Math.min(...snapPointDifferences);
    if (smallestSnapPointDifference < 1e-10) {
      const snapPointIndex = snapPointDifferences.indexOf(
        smallestSnapPointDifference
      );
      onItemChange?.(snapPointIndex + 1);
    }

    setIsResettingAutoScroll(true);
    carouselRef.current.scrollLeft = newPosition;
  };

  const itemClassName = clsx('flex shrink-0 align-top', {
    'mx-0': isNoMargin,
    'mx-3': !isNoMargin,
    'first:ml-0': isNoBoundaryMargin,
    'last:mr-0': isNoBoundaryMargin,
    'rounded-5xl': isItemsRounded
  });
  const forcedItemWidth = useMemo(
    () =>
      itemsPerPage
        ? {
            width: `calc(${Math.round((1 / itemsPerPage) * 100)}% - ${
              2 * itemMargin
            }px)`
          }
        : undefined,
    [itemsPerPage]
  );

  useEffect(() => {
    if (!autoScrollInterval || isScrolling || isAutoScrollStopped) {
      return undefined;
    }
    if (isResettingAutoScroll) {
      setIsResettingAutoScroll(false);
    }

    const id = setInterval(slideRight, autoScrollInterval);
    return () => clearInterval(id);
  }, [
    autoScrollInterval,
    isPageLoaded,
    isResettingAutoScroll,
    isAutoScrollStopped
  ]);

  if (slideLeftRef) {
    // eslint-disable-next-line no-param-reassign
    slideLeftRef.current = slideLeft;
  }
  if (slideRightRef) {
    // eslint-disable-next-line no-param-reassign
    slideRightRef.current = slideRight;
  }

  const customStyleForSlider = () => {
    if (sliderType === 'discrete' && sliderPosition === 'inset-below') {
      return {
        transform: 'translate(-50%, 0)',
        width: 'calc(100% - 48px)'
      };
    }
    if (sliderType === 'hero') {
      return { transform: 'translate(-50%, 0)' };
    }

    return undefined;
  };

  return (
    <>
      {showArrows && isInsideDSLayout && carouselHeight && items.length > 1 && (
        <div
          className={clsx(
            'absolute hidden tablet:flex z-50 w-full mx-auto justify-between pointer-events-none',
            {
              'max-w-[1278px] ml-[-50px]': showButtonsOutsideLayout,
              'left-0 ml-0 px-10': !showButtonsOutsideLayout
            }
          )}
          ref={nextButtonRef}
        >
          <div className="pointer-events-auto">
            {isScrollLeftEnabled && items.length > 1 && (
              <Button
                appearance="quaternary"
                icon="arrow-left"
                iconOnly
                text="Slide left"
                aria-label="Slide left"
                onClick={slideLeft}
                size="small"
              />
            )}
          </div>
          <div className="pointer-events-auto">
            {isScrollRightEnabled && items.length > 1 && (
              <Button
                appearance="quaternary"
                icon="arrow-right"
                iconOnly
                text="Slide right"
                aria-label="Slide right"
                onClick={slideRight}
                size="small"
              />
            )}
          </div>
        </div>
      )}
      <div
        className={clsx('relative p-0 overflow-hidden', {
          'tablet:px-10 tablet:-mx-10 tablet:-my-6': itemsHoverState
        })}
        data-testid="carousel"
      >
        {showArrows &&
          isScrollLeftEnabled &&
          !isInsideDSLayout &&
          items.length > 1 && (
            <Button
              size="small"
              icon="arrow-left"
              text="Slide left"
              iconOnly
              onClick={slideLeft}
              className={clsx(
                'absolute z-30 hidden tablet:flex carousel-nav-arrow left-6',
                {
                  'ml-4': itemsHoverState,
                  'top-[323px] tablet:top-[448px] laptop:top-auto laptop:bottom-6':
                    sliderType === 'hero'
                }
              )}
              aria-label="Slide left"
            />
          )}

        {showArrows &&
          isScrollRightEnabled &&
          !isInsideDSLayout &&
          items.length > 1 && (
            <Button
              aria-label="Slide right"
              icon="arrow-right"
              iconOnly
              onClick={slideRight}
              text="Slide right"
              className={clsx(
                'absolute z-30 hidden tablet:flex carousel-nav-arrow right-6',
                {
                  'mr-4': itemsHoverState,
                  'top-[323px] tablet:top-[448px] laptop:top-auto laptop:bottom-6':
                    sliderType === 'hero'
                }
              )}
              size="small"
            />
          )}

        {sliderType === 'hero' && (
          <IconButton
            aria-label="Slide right"
            icon={!isAutoScrollStopped ? PauseLine : PlayLine}
            onClick={togglePause}
            className={clsx(
              'absolute z-30 hidden laptop:flex carousel-nav-arrow right-[75px]',
              {
                'mr-4': itemsHoverState,
                'top-[323px] tablet:top-[448px] laptop:top-auto laptop:bottom-6':
                  sliderType === 'hero'
              }
            )}
          />
        )}

        <ul
          ref={carouselRef}
          onScroll={isSnapScrolling ? onScroll : undefined}
          className={clsx(
            'active:cursor-[grabbing] leading-[0] items relative carousel w-full overflow-hidden flex shrink-0',
            'cursor-pointer select-none whitespace-nowrap',
            {
              'active-grab': isScrolling,
              'mb-5': showSlider && sliderPosition === 'below',
              'tablet:max-w-fluid tablet:mx-auto px-0': isInFluid,
              'p-6 -m-6 ml-2 tablet:!-mx-6 tablet:!px-6 tablet:!my-0 tablet:!cardsInCarousel':
                itemsHoverState
            },
            itemContainerClassName
          )}
          onMouseDown={startScroll}
          onMouseUp={stopScroll}
          onMouseMove={scroll}
          onMouseLeave={stopScroll}
          onTouchStart={startScrollTouch}
          onTouchEnd={stopScroll}
          onTouchMove={scrollTouch}
        >
          {items.map((item, index) => (
            <li
              style={{
                ...forcedItemWidth,
                ...(isForcedItemHeight && carouselRef.current
                  ? { height: `${carouselRef.current.offsetHeight}px` }
                  : {})
              }}
              key={index}
              className={itemClassName}
              data-testid="carousel-item"
            >
              {item}
            </li>
          ))}
        </ul>
        {showSlider && (
          <div
            className={clsx(
              {
                'absolute z-10 left-1/2': sliderPosition === 'inset-below',
                'bottom-10':
                  sliderPosition === 'inset-below' &&
                  (sliderSize === 'normal' || sliderType === 'hero'),
                'bottom-6':
                  sliderPosition === 'inset-below' &&
                  (sliderSize === 'small' || sliderSize === 'full'),
                'w-full px-p-5 tablet:px-p-20 laptop:px-p-10 top-[350px] tablet:top-[467px] laptop:top-auto':
                  sliderType === 'hero',
                'bottom-auto': sliderType === 'hero' && !isLaptop
              },
              sliderClassName
            )}
            style={customStyleForSlider()}
          >
            {sliderType === 'hero' && (
              <CarouselSliderHero
                totalItems={items.length}
                onChange={onSliderChange}
                carouselPosition={carouselPosition}
                carouselSnapPoints={normalizedSnapPoints}
                isDark={isDarkSlider}
                autoScrollInterval={
                  isAutoScrollStopped ? 0 : autoScrollInterval
                }
              />
            )}
            {sliderType === 'discrete' && (
              <CarouselSliderDiscrete
                size={sliderSize}
                mode={mode}
                totalItems={items.length}
                onChange={onSliderChange}
                carouselPosition={carouselPosition}
                carouselSnapPoints={normalizedSnapPoints}
                isDark={isDarkSlider}
                autoScrollInterval={autoScrollInterval}
                hasDiscretePadding={hasDiscretePadding}
                hasLaptopPadding={hasLaptopPadding}
              />
            )}
            {sliderType === 'continuous' && (
              <CarouselSlider
                mode={mode}
                totalItems={items.length}
                onChange={onSliderChange}
                carouselPosition={carouselPosition}
                carouselSnapPoints={normalizedSnapPoints}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Carousel;
